import React from 'react';
import '../App.css';

function Footer() {
    return (
        <section id="footer">
            <footer>
                <p style={{textAlign: "center", color: "white"}}>Ilyass TARHRI</p>
            </footer>
        </section> 
    );
}

export default Footer;